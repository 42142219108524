



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
    Component,
    Vue,
	Watch
} from 'vue-property-decorator';
import {
    getComponent
} from '@/utils/helpers';
import wellTestModule from '@/store/modules/wellTestModule';
import draggable from 'vuedraggable';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { Debounce } from 'vue-debounce-decorator';
import { getNameByEmail } from '@/utils/users';
import { DateTime } from 'luxon';
import {
    addNumberPadding,
    sleep,
    dateDiff,
    getOffset,
    getElementPosition,
    getElementCenterPosition,
} from '@/utils/helpers'
import {
	SCHEDULE_BUCKET_REGION,
	SCHEDULE_BUCKET_NAME,
	IDENTITY_POOL_ID,
	IDENTITY_POOL_REGION,
	TASQ_WAITING_ON_LIST
} from '@/lib/constants'

import workspaceModule from '@/store/modules/workspaceModule';
import AWS from 'aws-sdk';
import schedule from '@/router/routes/schedule';
import { CheckboxSelectionComponent } from 'ag-grid-community';
import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    	TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
		Dropdown: () => getComponent('common/Dropdown'),
		Multiselect,
		draggable,
    },
})

export default class ScheduleV2 extends mixins() {
	refreshFilesKey = 0
	filesKeyCount = 1
	noFilesKeyCount = 1
	stored_zip_file;

	showDeleteFileOption = ''

	componentStatus: any = [{text: 'In Progress', value: 'In Progress', color: '#059bff', disabled: false}]
	editingDropdownType = ''

	months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

	editGroupPopupInnerShowing = false

	componentDateRange: any[] = []

	dropdownLocation: any[] = []

	waitingOnAction: any = {}

	statusOptions: any[] = [{text: 'New', value: 'New', color: '#aaaaaa', disabled: false}, {text: 'In Progress', value: 'In Progress', color: '#059bff', disabled: false}, {text: 'Waiting On', value: 'Waiting On', color: '#dba848', disabled: false}, {text: 'Scheduled', value: 'Scheduled', color: '#A775FF', disabled: false}, {text: 'Done', value: 'Done', color: '#34a865', disabled: false}]

	addJobIsLoading = false

	// Loading views
    dataLoading = false

	showDeleteComponentOption = false

	editingPreviousValue = null

	selectedComponentDetailsSummarySections: any[] = []

	userDropdownModel = []

	editingIndex = null

	showModal = false;

	itemTitleModel = ''

	windowWidth = window.innerWidth;

	startDateModal: any = ''
	dueDateModal: any = ''
	newChecklistItemInputModal = ''

	descriptionModel = ''

	isHoveringIndex = -1

	  actionErrors: any = [];


	testingMore = [{value: "One", checked: false}, {value: "Two", checked: false}, {value: "Three", checked: false}, {value: "Four", checked: false}, {value: "Five", checked: false}, {value: "Six", checked: false}, {value: "Seven", checked: false}, {value: "Eight", checked: false}]

	get isMobile() {
		return this.windowWidth < 769;
	}



	closeDialog() {
      this.showModal = false;
    }



  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  get kanbanTasq() {
    return tasqsListModule.kanbanSelectedTasq;
  }


  openPopupModal(wellName){


        const tasq = tasqsListModule.tasqList.find((t) => t.wellName === wellName);
        if (tasq && tasq.id) {
          tasqsListModule.setActiveTasq(tasq.id || '');
          tasqsListModule.setKanbanSelectedTasq(tasq?.id || '');
          this.showModal = true;
        } else {
          tasqsListModule.setKanbanSelectedTasq(wellName);
          assetsModule.setActiveProducingTasq(wellName);
          tasqsListModule.setActiveTasq('');
          this.showModal = true;
        }

  }



 openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  validateDialog() {
    if (!this.waitingOnAction.date) {
      this.actionErrors.push('date');
    }
    if (!this.waitingOnAction.reason) {
      this.actionErrors.push('reason');
    }
  }

	  prepareAction(scheduleJobId) {
    this.waitingOnAction = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      id: scheduleJobId,
      well: '',
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
this.waitingOnAction.type = 'wait';
        this.waitingOnAction.title = `Please select status for Schedule job:`;
        this.waitingOnAction.confirmText = 'Update';
        this.$dialog.show('actionModal');

    }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

    async onActionDialogClosed(response) {
    //   console.log(response)
       this.actionErrors = [];
			if (response.isOk) {
			this.validateDialog();
			if (this.actionErrors.length) {
				this.openDialog();
				return;
			}
          await scheduleModule.updateItemStatus({
                  // @ts-ignore
                item_id: this.waitingOnAction.id,
                item_status: 'Waiting On',
                payload: {
                  "Status":true,"WaitStartDate": new Date().toLocaleDateString(), "UntilDate": this.waitingOnAction.date,"WaitUntilReason":this.waitingOnAction.reason
                }
              })


          // this.$eventBus.$emit(SHOW_ALERT, successText);
      }
      this.waitingOnAction = {};
    }





	didFocusOnEditTitleRowText() {

	}

	async didLoseFocusOnEditTitleRowText() {
		for (var b = 0; b < this.activeColumns.length; b++) {
			if (this.activeColumns[b].ColumnType == "TITLE") {
				this.$emit('set-saving-details-banner-id', 'Updating title')
				await this.updateComponentTextValue(this.activeColumns[b], this.itemTitleModel, "VALUE")
			}
		}
	}
	didFocusOnEditDescriptionRowText(selectedComponentDetailsSection) {

	}
	async didLoseFocusOnEditDescriptionRowText(selectedComponentDetailsSection) {
		for (var b = 0; b < this.activeColumns.length; b++) {
			if (this.activeColumns[b].CustomID == selectedComponentDetailsSection.CustomID) {
				this.$emit('set-saving-details-banner-id', 'Updating description')
				await this.updateComponentTextValue(this.activeColumns[b], this.descriptionModel, "VALUE")
			}
		}
	}





	didHoverChecklist(index) {
		this.isHoveringIndex = index
	}

	didLeaveHoverChecklist(index) {
		this.isHoveringIndex = -1
	}

	activeMoveTasqID: any = null;



	async didEndChecklistDrag(sectionDetails) {
		for (var b = 0; b < this.activeColumns.length; b++) {
			if (this.activeColumns[b].CustomID == sectionDetails.CustomID) {

				this.$emit('set-saving-details-banner-id', 'Reordering checklist')

				var newResponse = JSON.parse(JSON.stringify(sectionDetails.Response))
				await this.updateComponentTextValue(this.activeColumns[b], newResponse, "VALUE")

			}
		}
	}


	checkMoveChecklist(event) {
		const { draggedContext } = (event);
		this.activeMoveTasqID = (draggedContext.element.id);
	}


	async deleteChecklistItem(checklist_index) {
		for (var b = 0; b < this.activeColumns.length; b++) {
			if (this.activeColumns[b].ColumnType == "CHECKLIST") {
				this.$emit('set-saving-details-banner-id', 'Removing from checklist')

				var newResponse = JSON.parse(JSON.stringify(this.activeColumns[b].Response))
				// newResponse.splice(index)

				// const index = newResponse.indexOf(checklist_index);
				if (checklist_index > -1) {
					newResponse.splice(checklist_index, 1);
				}
				await this.updateComponentTextValue(this.activeColumns[b], newResponse, "VALUE")
				this.$emit('update-component-details')
			}
		}
	}

	async addJobDidEnterChecklistItem(e) {
		if ((e.key === 'Enter' || e.keyCode === 13)) {
			if (this.newChecklistItemInputModal == '') {
				return
			}

			for (var b = 0; b < this.activeColumns.length; b++) {
				if (this.activeColumns[b].ColumnType == "CHECKLIST") {
					this.$emit('set-saving-details-banner-id', 'Adding to checklist')

					var newResponse = JSON.parse(JSON.stringify(this.activeColumns[b].Response))
					newResponse.push({
						checked: false,
						value: this.newChecklistItemInputModal
					})
					await this.updateComponentTextValue(this.activeColumns[b], newResponse, "VALUE")
					this.newChecklistItemInputModal = ''
					this.$emit('update-component-details')
				}
			}
		}
	}


	didChangeNewChecklistItemInput() {
		// console.log("we changed it")
		// console.log(this.newChecklistItemInputModal)





	}


	didSelectDropdown(e, type, index = null) {
		this.editingDropdownType = type
		// if (type == 'Status Changes' || type == 'Duplicate Tasq') {
		// 	console.log(e)
		// 	const rect = e.srcElement.parentElement.getBoundingClientRect()
		// 	this.dropdownLocation = [rect.x, rect.y + 60]
			this.editingIndex = index
		// } else {
			if (type == "User") {
				if (this.editingIndex != null) {
					// @ts-ignore
					this.editingPreviousValue = this.selectedComponentDetailsSummarySections[this.editingIndex].Response
				}


				const rect = e.srcElement.getBoundingClientRect()

				this.dropdownLocation = [rect.x - 140, rect.y + 38]
			}else if (type == "Core Status") {

				this.editingPreviousValue = this.componentStatus[0].value
			// @ts-ignore
				for (var x = 0; x < this.activeColumns.length; x++) {
					if (this.activeColumns[x].ColumnType == "STATUS" && this.activeColumns[x].CoreField) {
						// @ts-ignore
						this.editingIndex = x

					}
				}
				const rect = e.srcElement.getBoundingClientRect()
				this.dropdownLocation = [rect.x - 0, rect.y + 38]
			} else {

				const rect = e.srcElement.getBoundingClientRect()

				this.dropdownLocation = [rect.x - 0, rect.y + 38]
			}

		// }

	}



	async didSelectDropdownOption() {
		if (this.editingIndex != null && this.editingDropdownType == "User") {
			// @ts-ignore
			this.selectedComponentDetailsSummarySections[this.editingIndex].Response = this.userDropdownModel[0].value


			// @ts-ignore
			if (this.editingPreviousValue != this.selectedComponentDetailsSummarySections[this.editingIndex].Response) {
				this.$emit('set-saving-details-banner-id', 'Updating item')
				// @ts-ignore
				this.updateComponentTextValue(this.selectedComponentDetailsSummarySections[this.editingIndex], this.selectedComponentDetailsSummarySections[this.editingIndex].Response, 'VALUE')
			}
			this.editingIndex = null
			this.userDropdownModel = []
			this.editingDropdownType = ''
		} else if (this.editingIndex != null && this.editingDropdownType == "Core Status") {

			if(this.componentStatus[0].value === 'Waiting On'){
				this.prepareAction(this.activeComponent.ID)
				//  this.showModal = true;
			}
			// @ts-ignore
			var localActiveColumn = JSON.parse(JSON.stringify(this.activeColumns[this.editingIndex]))
			localActiveColumn.Response = {
				Status: this.componentStatus[0].value,
				Payload: {}
			}

			// @ts-ignore
			if (this.editingPreviousValue != localActiveColumn.Response) {
				this.$emit('set-saving-details-banner-id', 'Updating item')
				// @ts-ignore
				await this.updateComponentTextValue(localActiveColumn, localActiveColumn.Response, 'VALUE')

				// scheduleModule.setActiveComponent(null)
				this.$emit('add-data-to-gantt-chart')

				this.$emit('remove-saving-details-banner-id', 5000, 'Status updated')


			}
			// this.activeColumns[this.editingIndex].Response = this.componentStatus[0].value


			this.editingIndex = null
			this.userDropdownModel = []
			this.editingDropdownType = ''
		}



		this.dropdownLocation = []


	}


	hideDropdown() {
		this.dropdownLocation = []
	}

	ignoreStartDatePicker = false
	ignoreEndDatePicker = false
	async datepickerDidUpdateStartDate() {
		if (this.startDateModal == '' || this.ignoreStartDatePicker) {
			this.ignoreStartDatePicker = false
			return
		}

		var startDateObj = new Date(this.startDateModal)
		var dueDateObj = new Date(this.dueDateModal)
		startDateObj.setDate(startDateObj.getDate() + 1)
		dueDateObj.setDate(dueDateObj.getDate())


		if (dueDateObj <= startDateObj) {
			this.ignoreStartDatePicker = true
			this.setStartDateRange()

		// @ts-ignore
		} else if (this.getStartDateRange() != '' && (this.getStartDateRange()).getTime() != startDateObj.getTime()) {
			for (var x = 0; x < this.activeColumns.length; x++) {
				if (this.activeComponent.Columns[x].ColumnType == "START_DATE") {
					// @ts-ignore
					this.editingIndex = x
				}
			}
			// @ts-ignore
				var localActiveColumn = JSON.parse(JSON.stringify(this.activeColumns[this.editingIndex]))
				localActiveColumn.Response = this.startDateModal


				// @ts-ignore
				// if (this.editingPreviousValue != localActiveColumn.Response) {
				this.$emit('set-saving-details-banner-id', 'Updating item')
				// @ts-ignore
				await this.updateComponentTextValue(localActiveColumn, localActiveColumn.Response, 'VALUE')

				// scheduleModule.setActiveComponent(null)
				this.$emit('add-data-to-gantt-chart')

				this.$emit('remove-saving-details-banner-id', 5000, 'Status updated')

		}

	}

	async datepickerDidUpdateEndDate() {
		if (this.dueDateModal == '' || this.ignoreEndDatePicker) {
			this.ignoreEndDatePicker = false
			return
		}
		var startDateObj = new Date(this.startDateModal)
		var dueDateObj = new Date(this.dueDateModal)
		startDateObj.setDate(startDateObj.getDate())
		dueDateObj.setDate(dueDateObj.getDate() + 1)
		if (dueDateObj <= startDateObj) {
			this.ignoreEndDatePicker = true
			this.setDueDateRange()
			return
			// @ts-ignore
		} else if (this.getDueDateRange() != '' && (this.getDueDateRange()).getTime() != dueDateObj.getTime()) {
			for (var x = 0; x < this.activeColumns.length; x++) {
				if (this.activeComponent.Columns[x].ColumnType == "DUE_DATE") {
					// @ts-ignore
					this.editingIndex = x
				}
			}

			// @ts-ignore
			var localActiveColumn = JSON.parse(JSON.stringify(this.activeColumns[this.editingIndex]))
			localActiveColumn.Response = this.dueDateModal


			// @ts-ignore
			// if (this.editingPreviousValue != localActiveColumn.Response) {
			this.$emit('set-saving-details-banner-id', 'Updating item')
			// @ts-ignore
			await this.updateComponentTextValue(localActiveColumn, localActiveColumn.Response, 'VALUE')

			// scheduleModule.setActiveComponent(null)
			this.$emit('add-data-to-gantt-chart')

			this.$emit('remove-saving-details-banner-id', 5000, 'Status updated')


		}

	}

	datepickerDidUpdateSingleDate(e) {
		// this.$emit('update-single-item-date-range', e[0], e[1], this.activeComponent.ID)
	}

	datepickerDidUpdate(e) {
		if (e.length > 1) {
			this.$emit('update-single-item-date-range', e[0], e[1], this.activeComponent.ID)
		}
	}

	didFocusOnEditRowText(sectionDetails, type) {
		// type: KEY | VALUE
		if (type == "VALUE") {
			this.editingPreviousValue = sectionDetails.Response
		} else {
			this.editingPreviousValue = sectionDetails.CustomName
		}

	}

	didLoseFocusOnEditRowText(sectionDetails, index, type) {
		// type: KEY | VALUE
		this.$emit('set-saving-details-banner-id', 'Updating item')

		if (type == "VALUE") {
			for (var x = 0; x < this.selectedComponentDetailsSummarySections.length; x++) {
				if (this.selectedComponentDetailsSummarySections[x].CustomID == sectionDetails.CustomID) {
					if (this.editingPreviousValue != this.selectedComponentDetailsSummarySections[x].Response) {
						this.updateComponentTextValue(sectionDetails, this.selectedComponentDetailsSummarySections[x].Response, type)
						break
					}
				}
			}
		} else {
			for (var x = 0; x < this.selectedComponentDetailsSummarySections.length; x++) {
				if (this.selectedComponentDetailsSummarySections[x].CustomID == sectionDetails.CustomID) {
					if (this.editingPreviousValue != this.selectedComponentDetailsSummarySections[x].CustomName) {
						this.updateComponentTextValue(sectionDetails, this.selectedComponentDetailsSummarySections[x].CustomName, type)
						break
					}
				}
			}
		}
		this.editingPreviousValue = null
	}


	async updateComponentTextValue(sectionDetails, newResponse, type) {

		if (type == 'VALUE') {
			for (var b = 0; b < this.activeColumns.length; b++) {

				if (this.activeColumns[b].CustomID != sectionDetails.CustomID) {
					continue
				}
				var localColumn = JSON.parse(JSON.stringify(this.activeColumns[b]))



				localColumn.Response = newResponse
				scheduleModule.updateColumnByIndex({
					column_index: b,
					column: localColumn
				})
				await scheduleModule.updateScheduleItem({
					item_id: this.activeComponent.ID,
					columns: JSON.stringify(this.activeColumns)
				})
				break
			}
		} else {
			for (var b = 0; b < this.activeColumns.length; b++) {

				if (this.activeColumns[b].CustomID != sectionDetails.CustomID) {
					continue
				}
				var localColumn = JSON.parse(JSON.stringify(this.activeColumns[b]))



				localColumn.CustomName = newResponse
				scheduleModule.updateColumnByIndex({
					column_index: b,
					column: localColumn
				})
				await scheduleModule.updateScheduleItem({
					item_id: this.activeComponent.ID,
					columns: JSON.stringify(this.activeColumns)
				})
				break
			}
		}

		this.$emit('remove-saving-details-banner-id', 5000, 'Item was updated')
	}


	getStartDateRange() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "START_DATE") {
				var start_date = this.activeComponent.Columns[x].Response

				this.componentDateRange = []
				var start_date_object = new Date(start_date)
				start_date_object.setDate(start_date_object.getDate() + 1);
				// this.startDateModal = start_date_object
				return start_date_object
				// return start_date
				// Nov 12th, 2021
			}
		}
		return ''
	}
	// startDateModal = ''
	// dueDateModal = ''
	getDueDateRange() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "DUE_DATE") {
				var due_date = this.activeComponent.Columns[x].Response

				this.componentDateRange = []
				var due_date_object = new Date(due_date)
				due_date_object.setDate(due_date_object.getDate() + 1);
				// this.dueDateModal = due_date_object
				return due_date_object
				// return start_date
				// Nov 12th, 2021
			}
		}
		return ''
	}




	setDescription() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "DESCRIPTION" && this.activeComponent.Columns[x].CoreField) {
				this.descriptionModel = this.activeComponent.Columns[x].Response
				return
			}
		}
	}


	setTitle() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "TITLE" && this.activeComponent.Columns[x].CoreField) {
				this.itemTitleModel = this.activeComponent.Columns[x].Response
				return
			}
		}
	}



	setStartDateRange() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "START_DATE") {
				var start_date = this.activeComponent.Columns[x].Response

				this.componentDateRange = []
				var start_date_object = new Date(start_date)
				start_date_object.setDate(start_date_object.getDate() + 1);
				this.startDateModal = start_date_object
				return
				// return start_date
				// Nov 12th, 2021
			}
		}
	}
	// startDateModal = ''
	// dueDateModal = ''
	setDueDateRange() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "DUE_DATE") {
				var due_date = this.activeComponent.Columns[x].Response

				this.componentDateRange = []
				var due_date_object = new Date(due_date)
				due_date_object.setDate(due_date_object.getDate() + 1);
				this.dueDateModal = due_date_object
				return
				// return start_date
				// Nov 12th, 2021
			}
		}
	}


	async created() {

		scheduleModule.setSelectedComponentTabs()
		this.setupSelectedComponentDetailsSummarySections()
		this.setComponentDateRange()
		this.setStartDateRange()
		this.setDueDateRange()
		this.setTitle()
		this.setDescription()
	}

	fileMoreIconSelected(s3_url) {
		if (this.showDeleteFileOption == s3_url) {
			this.showDeleteFileOption = ''
		} else {
			this.showDeleteFileOption = s3_url
		}

	}



	async deleteComponentOptionSelected() {

		this.$emit('set-saving-details-banner-id', 'Deleting item')

		// @set-saving-details-banner-id="setSavingDetailsBannerID"
		// @remove-saving-details-banner-id="removeSavingDetailsBannerID"
		// @set-saving-details-banner-id-text="setSavingDetailsBannerIDText"




		await scheduleModule.deleteItem({
			item_id: this.activeComponent.ID,
		})

		scheduleModule.setActiveComponent(null)
		this.$emit('add-data-to-gantt-chart')

		this.$emit('remove-saving-details-banner-id', 5000, 'Item deleted')


	}

	confirmDeleteFile(file) {
		scheduleModule.setConfirmDeleteFile(file)

	}


	getFullNameByEmail(email) {
		return getNameByEmail(email)
	}

	expandFile(attachedFile) {
		scheduleModule.setExpandedFile(attachedFile)
	}


	selectedTabIDIsFile(selectedTabID) {
		for (var x = 0; x < this.selectedComponentTabs.length; x++) {
			if (this.selectedComponentTabs[x].TabID == selectedTabID && this.selectedComponentTabs[x].ColumnType == 'FILE') {
				return true
			}
		}
		return false
	}


	get selectedTabID() {
		return scheduleModule.selectedTabID
	}


    get enabledWells() {
        return assetsModule.enabledWells;
    }


	get availableUsers() {
		return accountModule.reassignmentList.map((i) => ({
		// @ts-ignore
		text: i.name,
		value: i.email,
		}));
	}


	setupSelectedComponentDetailsSummarySections() {

		var sections = []
		if (this.activeComponent == null) {
			return sections
		}
		// @ts-ignore
		for (var x = 0; x < this.activeColumns.length; x++) {
			// @ts-ignore
			if (this.activeColumns[x].ColumnType != "FILE" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "PROCEDURE" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "START_DATE" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "DUE_DATE" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "TASQ_DATE_RANGE" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "TITLE" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "DESCRIPTION" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "STATUS" &&
			// @ts-ignore
			this.activeColumns[x].ColumnType != "CHECKLIST") {
				// @ts-ignore
				sections.push(JSON.parse(JSON.stringify(this.activeColumns[x])))

			} else if (this.activeColumns[x].ColumnType == "STATUS" && this.activeColumns[x].CoreField) {


	// statusOptions: any[] = [{text: 'New', value: 'New', color: '#aaaaaa', disabled: false}, {text: 'In Progress', value: 'In Progress', color: '#059bff', disabled: false}, {text: 'Waiting On', value: 'Waiting On', color: '#dba848', disabled: false}, {text: 'Scheduled', value: 'Scheduled', color: '#A775FF', disabled: false}, {text: 'Done', value: 'Done', color: '#34a865', disabled: false}]

				this.componentStatus = [{text: 'Scheduled', value: 'Scheduled', color: '#A775FF', disabled: false}]
				for (var z = 0; z < this.statusOptions.length; z++) {
					var response_val = ''
					if (this.activeColumns[x].Response.constructor == Object) {
						response_val = this.activeColumns[x].Response.Status
					} else {
						response_val = this.statusOptions[z]
					}
					if (this.statusOptions[z].value == response_val) {
						this.componentStatus = [this.statusOptions[z]]
					}
				}

			}
		}

		this.selectedComponentDetailsSummarySections = JSON.parse(JSON.stringify(sections))
	}


	get selectedComponentDetailsSections() {
		var sections = []
		if (this.activeComponent == null) {
			return sections
		}
		// @ts-ignore
		for (var x = 0; x < this.activeColumns.length; x++) {
			// @ts-ignore
			if (this.activeColumns[x].ColumnType == "CHECKLIST") {
					// @ts-ignore
					sections.push(JSON.parse(JSON.stringify(this.activeColumns[x])))
					// sections.splice(0, 0, JSON.parse(JSON.stringify(this.activeColumns[x])));
			}
			// @ts-ignore
			if (this.activeColumns[x].ColumnType == "DESCRIPTION") {
					// @ts-ignore
					sections.splice(0, 0, JSON.parse(JSON.stringify(this.activeColumns[x])));
			}
		}
		console.log("we are returning sections tho:..")
		// console.log(sections)
		return sections
	}


	get selectedComponentTabs() {

		return scheduleModule.activeComponentTabs
	}

	get activeComponent() {
		return scheduleModule.activeComponent
	}

	get activeColumns() {
		return scheduleModule.activeComponentResponseColumns
	}





	closeActiveItem() {
		scheduleModule.setActiveComponent(null)
	}


	getAttachedFiles() {
		for (var x = 0; x < this.activeColumns.length; x++) {
			if (this.activeColumns[x].ColumnType == "FILE") {
				if (this.activeColumns[x].Response == null || this.activeColumns[x].Response == undefined) {
					return []
				}
				return this.activeColumns[x].Response
			}
		}
		return []
	}






	setComponentDateRange() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "TASQ_DATE_RANGE") {
				var start_date = this.activeComponent.Columns[x].Response[0]
				var end_date = this.activeComponent.Columns[x].Response[1]

				this.componentDateRange = []
				var start_date_object = new Date(start_date)
				var end_date_object = new Date(end_date)
				start_date_object.setDate(start_date_object.getDate() + 1);
				end_date_object.setDate(end_date_object.getDate() + 1);
				this.componentDateRange.push(start_date_object)
				this.componentDateRange.push(end_date_object)
				return
				// return start_date
				// Nov 12th, 2021
			}
		}
	}

	getComponentStartDateString() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "START_DATE") {
				var start_date = this.activeComponent.Columns[x].Response
				var year = start_date.split("-")[0]
				var month = start_date.split("-")[1]
				var day = start_date.split("-")[2]
				return this.months[month - 1] + " " + day.toString() + ", " + year
			}
		}
	}

	getComponentEndDateString() {
		for (var x = 0; x < this.activeComponent.Columns.length; x++) {
			if (this.activeComponent.Columns[x].ColumnType == "DUE_DATE") {
				var end_date = this.activeComponent.Columns[x].Response
				var year = end_date.split("-")[0]
				var month = end_date.split("-")[1]
				var day = end_date.split("-")[2]
				return this.months[month - 1] + " " + day.toString() + ", " + year
			}
		}
		return 'Due Date'
	}

	async setSelectedComponentTab(selectedTabID) {
		scheduleModule.setSelectedTabID(selectedTabID)
		await sleep(400)
		this.setupDragAndDropSection()
	}


	setupNewLayerDropArea() {
		if (this.stored_zip_file != null) {
			// if (document.getElementById('afterShapefileUploadID') != null) {
			// 	// @ts-ignore
			// 	document.getElementById('afterShapefileUploadID').style.display = 'grid';
			// }
			// if (document.getElementById('defaultShapefileUploadAreaID') != null) {
			// 	// @ts-ignore
			// 	document.getElementById('defaultShapefileUploadAreaID').style.display = 'none';
			// }
			// if (document.getElementById('fileDragName') != null) {
			// 	// @ts-ignore
			// 	document.getElementById('fileDragName').value = this.stored_zip_file.name;
			// 	// @ts-ignore
			// 	document.getElementById('fileDragSize').value = this.stored_zip_file.size;
			// 	// @ts-ignore
			// 	document.getElementById('fileDragType').value = this.stored_zip_file.type;
			// }
			this.addNewFile()
		} else {
			// @ts-ignore
				document.getElementById('afterShapefileUploadID').style.display = 'none';
				// @ts-ignore
				document.getElementById('defaultShapefileUploadAreaID').style.display = 'block';
				// @ts-ignore
				document.getElementById('fileDragName').value = '';
				// @ts-ignore
				document.getElementById('fileDragSize').value = '';
				// @ts-ignore
				document.getElementById('fileDragType').value = '';
		}
	}


	onFileInputChange(e) {
		const inputElement = document.getElementById('add_shapefile_input') !;
		// @ts-ignore
		const fileList = inputElement.files;
		this.stored_zip_file = fileList[0];
		this.setupNewLayerDropArea();
		// TODO do something with fileList.
	};

	onNewFileInputChange(e) {
		const inputElement = document.getElementById('new_add_shapefile_input') !;
		// @ts-ignore
		const fileList = inputElement.files;
		this.stored_zip_file = fileList[0];
		this.setupNewLayerDropArea();
		// TODO do something with fileList.
	};

	onFileDrop(e) {
		// @ts-ignore
		this.className = '';
		e.preventDefault();
		// @ts-ignore
		this.stored_zip_file = e.dataTransfer.files[0];
		this.setupNewLayerDropArea();
	};

	onFileDragLeave() {
		// @ts-ignore
		this.className = '';
		return false;
	};

	onFileDragEnd() {
		// @ts-ignore
		this.className = '';
		return false;
	};

	onFileDragExit() {
		// @ts-ignore
		this.className = '';
		return false;
	};

	onFileDragOver() {
		// @ts-ignore
		this.className = 'hover';
		return false;
	};

	setupDragAndDropSection() {
		sleep(500).then(() => {
			const inputElement = document.getElementById('add_shapefile_input') !;
			// inputElement.onchange = this.onFileInputChange;

// new_add_shapefile_input

			const holder = document.getElementById('holder');
			// @ts-ignore
			holder.ondragover = this.onFileDragOver
			// @ts-ignore
			holder.ondragexit = this.onFileDragExit
			// @ts-ignore
			holder.ondragend = this.onFileDragEnd
			// @ts-ignore
			holder.ondragleave = this.onFileDragLeave
			// @ts-ignore
			holder.ondrop = this.onFileDrop


			const newInputElement = document.getElementById('new_add_shapefile_input') !;
			newInputElement.onchange = this.onNewFileInputChange;

		});
	}



	processScheduledItemFile(file) {
		const extension = file.name.split('.').pop();

		var operator = getConfigEnv('OPERATOR_LOWERCASED')

		// document.getElementById('shapefileUploadLoadingViewID') !.style.display = 'block';
		// document.getElementById('shapefileUploadStatusID') !.innerHTML = 'Uploading file...';

		this.$emit('set-saving-details-banner-id', 'Uploading "' + file.name + '"')

		// @set-saving-details-banner-id="setSavingDetailsBannerID"
		// @remove-saving-details-banner-id="removeSavingDetailsBannerID"
		// @set-saving-details-banner-id-text="setSavingDetailsBannerIDText"





		AWS.config.update({
		region: SCHEDULE_BUCKET_REGION,
		credentials: new AWS.CognitoIdentityCredentials({
			IdentityPoolId: IDENTITY_POOL_ID,
		}),
		});

		const s3 = new AWS.S3({
		apiVersion: '2006-03-01',
		params: {
			Bucket: SCHEDULE_BUCKET_NAME,
		},
		});

	const key = `${operator}/${accountModule.user.email.toLowerCase()}/${file.name}`;
		if (extension == "PNG" || extension == "png" || extension == "jpeg" || extension == "jpg") {

		s3.upload({
			Key: key,
			Bucket: SCHEDULE_BUCKET_NAME,
			Body: file,
			ACL: 'public-read',
			},
			(err, data) => {
			if (err) {
				console.log(err, 'there was an error uploading your file');
			} else {
				const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
				console.log(`URL Generated: ${s3_url}`);
				this.addFileToItem(s3_url, extension, file.name)
			}
			});
		} else if (extension == "pdf") {

			s3.upload({
			Key: key,
			Bucket: SCHEDULE_BUCKET_NAME,
			Body: file,
			ContentDisposition: 'inline',
			ACL: 'public-read',
			ContentType: "application/pdf"
			},
			(err, data) => {
			if (err) {
				console.log(err, 'there was an error uploading your file');
			} else {
				const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;
				console.log(`URL Generated: ${s3_url}`);
				this.addFileToItem(s3_url, extension, file.name)
			}
			});
		}

	}

	async addFileToItem(s3_url, extension, file_name) {

		for (var b = 0; b < this.activeColumns.length; b++) {
			if (this.activeColumns[b].ColumnType != "FILE") {
				continue
			}
			var localColumn = JSON.parse(JSON.stringify(this.activeColumns[b]))
			if (localColumn.Response == null) {
				localColumn.Response = []
			}


			localColumn.Response.push({
				S3Url: s3_url,
				FileName: file_name,
				Username: accountModule.user.email.toLowerCase(),
				Extension: extension,
				DateCreated: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss"),
				DateModified: DateTime.utc().toFormat("y-MM-dd'T'HH:mm:ss")

			})
			scheduleModule.updateColumnByIndex({
				column_index: b,
				column: localColumn
			})
			await scheduleModule.updateScheduleItem({
				item_id: this.activeComponent.ID,
				columns: JSON.stringify(this.activeColumns)
			})

		}
		this.filesKeyCount += 1
		this.noFilesKeyCount += 1
		this.refreshFilesKey += 1
		this.$emit('remove-saving-details-banner-id', 5000, 'Uploaded "' + file_name + '"')
		this.$emit('update-component-details')
	}


	readfiles(file) {
		if (file) {
			this.processScheduledItemFile(file);
		}
	}

	addNewFile() {
	  this.dataLoading = true;
	  if (this.stored_zip_file != null) {
	    this.readfiles(this.stored_zip_file);
	  }
	}


	async didSelectChecklistItem(item, index) {
		for (var x = 0; x < this.activeColumns.length; x++) {
			if (this.activeColumns[x].ID == item.ID && this.activeColumns[x].CustomName == item.CustomName) {
				scheduleModule.updateActiveColumnsChecklist({single_index: index, single_x: x})



				var localColumn = JSON.parse(JSON.stringify(this.activeColumns[x]))
				scheduleModule.updateColumnByIndex({
					column_index: x,
					column: localColumn
				})

			}
		}

		this.$emit('update-component-details')

		// var objDiv = document.getElementById("scrollableComponent");

		// var div = document.getElementById(id);
		// @ts-ignore
		// objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;

		// @ts-ignore
		// objDiv.scrollTop = objDiv.scrollHeight;
		await scheduleModule.updateScheduleItem({
			item_id: item.ItemID,
			columns: JSON.stringify(this.activeColumns)
		})


		// this.showSaveSuccessfulNotification = true
		// await sleep(4000)
		// this.showSaveSuccessfulNotification = false

	}


	getFullComponentDetailsByID(id) {
		// @ts-ignore
		for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
			// @ts-ignore
			for (var y = 0; y < workspaceModule.activeTable.Groups[x].Items.length; y++) {
				// @ts-ignore
				if (id == workspaceModule.activeTable.Groups[x].Items[y].ID) {
					// @ts-ignore
					return workspaceModule.activeTable.Groups[x].Items[y]
				}
			}
		}
	}



	showComponentDetails(component) {
		var detailedComponent = this.getFullComponentDetailsByID(component.component_id)
		if (detailedComponent != null) {
			scheduleModule.setActiveComponent(detailedComponent)
			for (var x = 0; x < detailedComponent.Columns.length; x++) {
				detailedComponent.Columns[x].ItemID = detailedComponent.ID
			}
			scheduleModule.setActiveComponentResponseColumns(detailedComponent.Columns)
		}
	}





  getColumnTypeIcon(column_type) {
	  if (column_type == "WELL") {
		  return "task"
	  } else if (column_type == "USER") {
		  return "person"
	  } else if (column_type == "VENDOR") {
		  return "engineering"
	  } else if (column_type == "CHECKLIST") {
		  return "checklist"
	  } else if (column_type == "FILE") {
		  return "picture_as_pdf"
	  } else if (column_type == "TESTERS") {
		  return "quiz"
	  } else if (column_type == "TASQ_DATE_RANGE") {
		  return "date_range"
	  } else if (column_type == "COST") {
		  return "attach_money"
	  } else if (column_type == "TAGS") {
		  return "tag"
	  } else if (column_type == "TEXT") {
		  return "format_size"
	  } else if (column_type == "DATE") {
		  return "calendar"
	  } else if (column_type == "PROCEDURE") {
		  return "task"
	  } else if (column_type == "EMAIL") {
		  return "email"
	  } else if (column_type == "PHONE_NUMBER") {
		  return "phone"
	  } else {
		  return "task"
	  }
  }

    @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if (id) {
      this.showModal = true;
    } else {
      this.$dialog.hide('actionModal');
      this.showModal = false;

      // this.setLoffding()
    }
  }


}




